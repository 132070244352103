/* HEADER */

#tagline {
  text-align: center;
  font-weight: 100;
  font-size: 32px;
  color: #fff;
}

#header-btn {
  text-align: center;
  margin-bottom: 40px;
}

#header-img {
  max-width: 90%;
  margin-bottom: -40px;
}

/**********************
 ******4. About****
 **********************/

#about {
  background-color: #2a434a;
  position: relative;
  z-index: 1;
  text-align: center;
  img {
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
  }
}

/**********************
 ******5. Features****
 **********************/

#features {
  h4 {
    color: #f09f4a;
    margin-top: 0;
  }
  .col-sm-4.left {
    text-align: right;
  }
  .col-lg-4 .row {
    margin-top: 46px;
    position: relative;
    left: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }
}

.circle-icon-rspv {
  width: 100%;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    padding-bottom: calc(100% - 4px);
    border: 2px solid #f09f4a;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .icon {
    float: left;
    width: 100%;
    padding-top: 50%;
    line-height: 1em;
    margin-top: -0.5em;
    text-align: center;
    color: #f09f4a;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &:after {
      background-color: #f09f4a;
    }
    .icon {
      color: #fff;
    }
  }
}

#features {
  .left > .row:hover .circle-icon-rspv:after, .right > .row:hover .circle-icon-rspv:after {
    background-color: #f09f4a;
  }
  .left > .row:hover .icon, .right > .row:hover .icon {
    color: #fff;
  }
}

/**********************
 ******7. Download Banner****
 **********************/

#download-banner {
  background-color: #163037;
  padding: 40px 0;
  p {
    text-align: center;
    margin: 0;
    font-size: 18px;
  }
  strong {
    color: #fff;
    font-weight: 400;
    margin: 0 5px;
    display: inline-block;
  }
}

/**********************
 ******9. Reviews****
 **********************/

#reviews {
  background: #102129 url('../img/blurred-bg.jpg') no-repeat bottom center;
  background-size: cover;
  text-align: center;
  font-size: 18px;
  color: #bcc8cc;
  .avatar {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 5px;
    background-size: cover;
    border-radius: 40px;
  }
  .owl-item {
    &:nth-child(1) .avatar, &:nth-child(3) .avatar {
      background-image: url('../img/avatar1.jpg');
    }
    &:nth-child(2) .avatar {
      background-image: url('../img/avatar2.jpg');
    }
  }
  .quote {
    font-family: Merriweather, serif;
    font-weight: 300;
    font-style: italic;
    width: 96%;
    max-width: 560px;
    margin: 0 auto;
    position: relative;
    padding: 37px 0;
    line-height: 30px;
    &:before, &:after {
      content: '\f347';
      font-size: 64px;
      position: absolute;
      z-index: -1;
      color: #f09f4a;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      font-family: "Ionicons";
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: none;
    }
    &:before {
      left: -55px;
      top: 0px;
    }
    &:after {
      right: -55px;
      bottom: 0px;
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      /* IE 9 */
      -webkit-transform: rotate(180deg);
      /* Opera, Chrome, and Safari */
    }
  }
  .author {
    .position {
      font-size: 14px;
      font-weight: 400;
      color: #576266;
    }
    padding-top: 32px;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 180px;
      border-bottom: 1px solid;
      border-color: #576266;
      position: relative;
      bottom: 32px;
    }
  }
}

/**********************
 ******10. Download****
 **********************/

#download {
  background-color: #2a434a;
  position: relative;
  overflow: hidden;
  .download-buttons {
    text-align: center;
    margin-top: -30px;
    position: relative;
    z-index: 1;
    a {
      margin: 20px 15px 0;
    }
  }
}

/**********************
 ******13. Owl Carousel****
 **********************/

.owl-theme .owl-controls {
  margin-top: 40px;
  .owl-page {
    span {
      width: 10px;
      height: 10px;
      box-shadow: 0 0 0 2px #f09f4a inset;
      opacity: 1;
      background: transparent;
    }
    &.active span {
      background-color: #f09f4a;
    }
  }
  &.clickable .owl-page:hover span {
    background-color: #f09f4a;
  }
}

/**********************
 ******14. Media Queries****
 **********************/
@media (max-width: 1199px) {
  #features {
    .col-lg-4 .row {
      margin-top: 8px;
    }
    h4 {
      margin-bottom: 2px;
    }
    .icon {
      font-size: 48px;
    }
  }
}

@media (max-width: 991px) {
  #about {
    p {
      margin: 8px auto 30px;
      max-width: 345px;
    }
    h3 {
      margin: 16px 0 8px;
    }
  }
  #features {
    .row .col-sm-4 .row .col-sm-3 {
      display: none;
    }
    .col-lg-4 .row {
      position: static;
    }
  }
  #header-btn {
    text-align: center;
  }
}

@media (max-width: 767px) {
  #features {
    p {
      max-width: 345px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 4px;
    }
    img {
      max-width: 90%;
      margin: 30px auto -40%;
    }
    .col-lg-4 .row {
      margin-top: 12px;
    }
  }
  #reviews .quote {
    &:before, &:after {
      color: rgba(0, 0, 0, 0.5);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  #download-banner .text {
    width: 100%;
    margin: 0 0 20px;
  }
}

@media (max-width: 530px) {
  #tagline {
    font-size: 22px;
  }
}

@media (max-width: 399px) {
  #header-btn {
    margin-bottom: 38px;
    a {
      margin: 0;
      &:first-child {
        margin: 0 10px 20px;
        width: 179px;
      }
    }
  }
  #menu-bar-fixed .logo img {
    height: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #features img {
    max-width: 124%;
    position: relative;
    left: -12%;
    margin-top: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #header-img {
    max-width: 95%;
  }
}
